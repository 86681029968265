// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "@fortawesome/fontawesome-free/js/all";
require("jquery");
require("bootstrap");
require("@nathanvda/cocoon");
// window.Noty = require('noty');

// Add custom files here
require("../packs/theme.min.js");
window.Noty = require("../packs/noty.js");

$(document).on("turbolinks:load", function () {
  // Custom logic here
  $(".copy_btn").on("click", function () {
    const id = this.parentNode.id;
    const user_data = $(`#user_data_${id}`).val() + "dna-questionnaire/" + id;
    navigator.clipboard.writeText(user_data);
    $(this).html("Copied!");
  });
});

Rails.start();
Turbolinks.start();
ActiveStorage.start();
